import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import SEO from '../../components/SEO'
import { Form } from '../../_shared/components'

export default function Page() {
  const COMPLETED_STEP = {
    title: 'Noe gikk feil',
    preamble:
      'Dette kan skyldes at kjøpet ble avbrutt eller at kommunikasjonen med Vipps feilet.',
    close: true,
    items: [
      {
        item: 'text',
        small: true,
        left: true,
        value:
          'Om betalingen er blitt bekreftet i Vipps vil du antagelig motta en epost med gavekort i løpet av noen minutter. Da er alt i orden.',
      },
      {
        item: 'text',
        small: true,
        left: true,
        value: (
          <>
            Hvis du ikke mottar gavekort og betalingen i Vipps er bekreftet, ta
            kontakt med oss på{' '}
            <a
              href="mailto:post@foodsteps.no"
              target="_blank"
              rel="noopener noreferrer"
            >
              post@foodsteps.no
            </a>
            , så rydder vi opp i det.
          </>
        ),
      },
    ],
  }

  return (
    <>
      <SEO title="Gavekort kjøp feilet" />
      <Form
        step="completed"
        setStep={() => {}}
        handleBack={() => navigate('/gavekort')}
        navigation
        steps={{
          completed: COMPLETED_STEP,
        }}
      />
    </>
  )
}
